import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import LoginPage from "./LoginPage";
import Footer from "../components/Footer";
import { Spinner } from "react-bootstrap";

const HomePage = () => {
  const [pageLoading, setPageLoading] = useState(false);

  if (pageLoading === true) {
    return (
      <main>
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <Spinner />
        </div>
      </main>
    );
  }

  return (
    <main>
      <section
        className="py-5 bg-cover-1"
        style={{ backgroundImage: `url(/assets/images/hero-image-1.jpg)` }}
      >
        <div className="container py-lg-4">
          <div className="text-center max-w-5 mx-auto">
            <div className="auth-logo text-center mb-1">
              <img
                src="/assets/images/logo-2-v2white.png"
                alt=".."
                style={{ objectFit: "contain" }}
              />
            </div>
            <h2 className="mb-2 fs-5 fw-semibold text-white">
              Vous souhaitez vendre votre bien le plus rapidement possible et au
              meilleur prix ?
            </h2>
            <h1 className="mb-0 fs-3 fw-bold text-white">
              Obtenez une estimation immobilière gratuite et instantanée !
            </h1>
          </div>
        </div>
      </section>
      <section
        className="bg-cover pt-3 pb-5"
        // style={{ backgroundImage: `url(/assets/images/bg-1.png)` }}
      >
        <div className="container pb-5">
          <div className="max-w-5 mx-auto">
            <div className="mb-4 max-w-6 mx-auto">
              <img
                className="d-block h-100 w-100 object-fit-contain"
                src="/assets/images/image-2.jpg"
                alt="..."
              />
            </div>
            <p className="mb-4 text-center fw-medium px-lg-5">
              La vente de votre propriété ne devrait pas être complexe ou
              stressante. Notre outil d'estimation immobilière instantanée vous
              libère des incertitudes et vous guide vers une décision éclairée,
              rapidement et sans effort, tout en assurant la plus grande
              discrétion et confidentialité.
            </p>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 justify-content-center gy-4">
              <div className="col">
                <div
                  className="card-4 h-100 p-4"
                  style={{ backgroundColor: "#EEEAF3" }}
                >
                  <div className="text-center">
                    <div className="mt-2">
                      <h4 className="text-primary font-poppins font-weight-500">
                        Estimation instantanée
                      </h4>
                      <p className="mb-0 font-poppins text-black-50">
                        Obtenez la valeur de votre propriété en un clic, sans
                        attente.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  className="card-4 h-100 p-4"
                  style={{ backgroundColor: "#EEEAF3" }}
                >
                  <div className="text-center">
                    <div className="mt-2">
                      <h4 className="text-primary font-poppins font-weight-500">
                        Discrétion et confidentialité
                      </h4>
                      <p className="mb-0 font-poppins text-black-50">
                        Estimez sans divulguer votre identité, ni vos plans.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  className="card-4 h-100 p-4"
                  style={{ backgroundColor: "#EEEAF3" }}
                >
                  <div className="text-center">
                    <div className="mt-2">
                      <h4 className="text-primary font-poppins font-weight-500">
                        Données réelles
                      </h4>
                      <p className="mb-0 font-poppins text-black-50">
                        Estimations basées sur de vraies données, pas juste des
                        annonces.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mx-auto max-w-3">
            <h4 className="text-center text-primary fw-bold lh-base mb-0">
              Découvrez la valeur réelle de votre bien, instantanément et sans
              prise de tête. Notre outil est votre premier pas vers une
              transaction réussie !
            </h4>
          </div>
          <div
            className="max-w-4 mx-auto p-4 my-5 "
            style={{
              border: "solid #5f3994",
              backgroundColor: "#EEEAF3",
              borderRadius: "3%",
            }}
          >
            {/* <div className="auth-logo text-center mb-1">
              <img
                src="/assets/images/logo-2.png"
                alt=".."
                style={{ objectFit: "contain" }}
              />
            </div>
            <h4 className="text-center text-primary fw-bold mb-0">
              Log Into your Account!
            </h4> */}
            <div>
              <LoginPage
                setPageLoading={setPageLoading}
                pageLoading={pageLoading}
              />
              {/* <form>
                <div className="form-group mb-3">
                  <label className="small fw-medium mb-1" htmlFor="username">
                    Full Name*
                  </label>
                  <input className="form-control" name="username" type="text" />
                </div>
                <div className="form-group mb-3">
                  <label className="small fw-medium mb-1" htmlFor="email">
                    Email*
                  </label>
                  <input className="form-control" type="email" name="email" />
                </div>
                <div className="form-group mb-3">
                  <label className="small fw-medium mb-1" htmlFor="phoneno">
                    Phone number*
                  </label>
                  <PhoneInput
                    country={"us"}
                    containerClass="w-100"
                    inputClass="w-100"
                  />
                </div>

                <button
                  className="btn btn-primary w-100 py-2 fw-bold mt-4"
                  type="submit"
                >
                  Login
                </button>
              </form> */}
            </div>
          </div>
          <div className="mx-auto max-w-3 mt-2">
            <p className="text-center small lh-base mb-0">
              <small>
                Cet outil d'estimation est conçu pour vous fournir une
                évaluation basée sur des données réelles, fiables et à jour.
                Toutefois, il est important de noter que les résultats sont des
                estimations et ne garantissent pas une précision à 100%. Les
                valeurs fournies sont destinées à être utilisées comme un guide
                dans votre processus de décision de vente et ne devraient pas
                remplacer une évaluation professionnelle.
              </small>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default HomePage;
