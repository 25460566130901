// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import "firebase/firestore";
import "firebase/compat/functions"
import firebaseConfig from "./firebaseConfig";

const app = firebase.initializeApp(firebaseConfig);
console.log("FirebaseConfig")
console.log(firebaseConfig)
const db = getFirestore(app);
const storage = getStorage(app);
firebase.firestore().settings({ timestampsInSnapshots: true, merge: true });
export const firestore = firebase.firestore();
const functions = firebase.functions();
export default firebase;
export { db, storage, functions };
