import React from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import HomePage from "./pages/HomePage";
import ThankyouPage from "./pages/ThankyouPage";
import StartEstimation from "./pages/StartEstimation";
import CookiesModal from "./components/CookiesModal";

const AppRouter = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

const AppContent = () => {
  return (
    <>
        <CookiesModal />
      <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/redirect" element={<ThankyouPage />} />
          <Route path="/start" element={<StartEstimation />} />
          <Route path={"*"} element={<Navigate to={"/"} />} />
      </Routes>
    </>
  );
};

export default AppRouter;
