import React, { useEffect, useState } from "react";
import firebase from "../firebase";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {Spinner} from "react-bootstrap";

const ThankyouPage = () => {
  const authState = useSelector((state) => state.firebase.auth || {});
  const [token, setToken] = useState(null);
  const [counter, setCounter] = useState(3);
  const navigate = useNavigate();
  useEffect(() => {
    if (!authState.uid) {
      navigate("/");
    }
    const getAccessToken = () => {
      const httpCallable = firebase
        .functions()
        .httpsCallable("createCustomAccessToken");
      httpCallable({ uid: authState.uid })
        .then((res) => {
          setToken(res.data.token);
        })
        .catch(() => {
          navigate("/");
        });
    };
    getAccessToken();
  }, []);

  useEffect(() => {
    if(counter > 0) {
      setTimeout(() => {
      setCounter(counter - 1);
      }, 1000);
    }
  }, [counter]);


  if(counter === 0 && token != null) {
    window.open(
      `https://ziaway.ca/evaluation-bien?token=${token}`,
      "_self"
    );
  }
  return (
    <main className="bg-gray-100 min-vh-100 d-flex flex-column">
      {/* <Header /> */}
      <div className="flex-fill text-center d-flex flex-column align-items-center justify-content-center p-3 p-sm-4">
        <h1 className="mb-5 display-5 font-weight-bold text-primary max-w-2">
          Votre compte a été créé avec succès
        </h1>
        <h2 className="mb-5 text-primary">
          Vous serez redirigé dans {counter} secondes pour effectuer votre estimation en
          ligne.
        </h2>
        <div className="position-relative">
          <Spinner animation="grow" variant={"primary"} size={"md"} />
        </div>
      </div>
    </main>
  );
};

export default ThankyouPage;
