import { configureStore } from "@reduxjs/toolkit";
import firebase from "../firebase";
import { firestoreReducer, reduxFirestore } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

export const store = configureStore({
  reducer: {
    firebase: firebaseReducer,
    firestore: firestoreReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

  enhancers: (defaultEnhancers) => {
    return [
      reduxFirestore(firebase), // Add your custom enhancer here
      // You can add more enhancers if needed
    ];
  },

  // enhancers: [reduxFirestore(firebase)],
});
