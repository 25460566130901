import React, { useEffect, useState, Fragment } from "react";
//import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import swal from "sweetalert2";
import firebase from "../firebase";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-number-input";
import ReactCodeInput from "react-verification-code-input";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const LoginPage = ({ setPageLoading, pageLoading }) => {
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);
  const [resendRecaptchaVerifier, setResendRecaptchaVerifier] = useState(null);
  const [verificationId, setVerificationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [verifying, setVerifying] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({ telephone: '+212654245581' });
  const [canResendCode, setCanResendCode] = useState(10);
  const [verificationCode, setVerificationCode] = useState("");
  const [showResendCode, setShowResendCode] = useState(false);
  const [captchaValidated, setCaptchaValidated] = useState(false);
  const defaultCountry = "CA";
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
    getValues,

  } = useForm();

  const isValidPhoneNumber = (value) => {
    const phoneNumberObject = parsePhoneNumberFromString(value);
    return !!phoneNumberObject && phoneNumberObject.isValid();
  };

  console.log('values', getValues());

  useEffect(() => {
    // Initialize reCAPTCHA verifier
    console.log(firebase.auth())
    const verifier = new firebase.auth.RecaptchaVerifier("recaptcha", {
      size: "normal", // 'invisible' is also an option
      hl: "fr-CA",
      callback: (response) => {
        // This callback will be called when the reCAPTCHA challenge is solved.
        setCaptchaSuccess(true);
        setValue("captcha", "done");
        // You can proceed with your authentication flow here.
      },
      "expired-callback": () => {
        setCaptchaSuccess(false);
        setValue("captcha", "");
      },
    });

    verifier.render().then((widgetId) => {
      setRecaptchaVerifier(verifier);
    });
  }, []);

  const handleSendCode = async (phoneNumber, recaptchaVerifier) => {
    console.log("running1");
    try {
      console.log("running2");
      const confirmation = await firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, recaptchaVerifier);

      setValue("captcha", "");
      setCaptchaSuccess(false);

      setVerificationId(confirmation.verificationId);
      console.log("confirmation", confirmation);
      setLoading(false);
      setShow(true);
      setCanResendCode(60);
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
      setValue("captcha", "");
      setCaptchaSuccess(false);
      setLoading(false);
    }
  };
  const zohoPostNewLead = (user) => {
    const func = firebase.functions().httpsCallable("useZohoPostNewLead");
    func({
      info: {
        data: [
          {
            Last_Name: user.displayName,
            Email: user.email,
            ConnectionSource: "lp",
            Phone: user.phoneNumber,
          },
        ],
      },
      vmodule: "Leads",
    });
  };

  const getAccessToken = (uid) => {
    const httpCallable = firebase
      .functions()
      .httpsCallable("createCustomAccessToken");
    httpCallable({ uid: uid })
      .then((res) => {
        window.open(
          "https://ziaway.ca/evaluation-bien?token=" + res.data.token,
          "_self"
        );
      })
      .catch(() => {
        navigate("/");
      });
  };

  const handleVerifyCode = async () => {
    setVerifying(true);

    try {
      // Verify the entered code
      const credential = firebase.auth.PhoneAuthProvider.credential(
        verificationId,
        verificationCode
      );

      const response = await firebase.auth().signInWithCredential(credential);
      firebase
        .firestore()
        .collection("users")
        .doc(response?.user?.uid)
        .get()
        .then((doc) => {
          zohoPostNewLead({
            displayName: data.username,
            email: data.email,
            app: "lp",
            phoneNumber: data.telephone,
          });
          if (!doc.exists) {
            firebase
              .firestore()
              .collection("users")
              .doc(response?.user?.uid)
              .set({
                userId: response?.user?.uid,
                role: "membre",
                displayName: data.username,
                phoneNumber: data.telephone,
                email: data.email,
              });
            navigate("/redirect");
          } else {
            console.log("doc exist");
            firebase
              .firestore()
              .collection("users")
              .doc(response?.user?.uid)
              .update({
                userId: response?.user?.uid,
                role: "membre",
                displayName: data.username,
                phoneNumber: data.telephone,
                email: data.email,
              });
            getAccessToken(response?.user?.uid);
          }
        })
        .catch((error) => {
          console.error("Error checking object:", error);
        });

      toast.success(`OTP Verified`);

      setShow(false);
      setVerifying(false);

      // setUserData({
      //   ...data,
      //   id: response?.user?.uid,
      //   verifiedAt: new Date().getTime(),
      // });
    } catch (error) {
      console.log(error);
      if (error.code === "auth/invalid-verification-code") {
        swal.fire(
          "Oups erreur!",
          "Nous nous attendions à une réponse différente. Veuillez réessayer.",
          "error"
        );
      } else {
        swal.fire("Oups erreur!", error?.message, "error");
      }

      // toast.error(error?.message);
      setShow(false);
      setVerifying(false);
    }
  };

  const onSubmit = (data) => {
    console.log("data", data);
    if (watch("captcha") || captchaSuccess === true) {
      setLoading(true);
      setData(data);
      handleSendCode(data?.telephone, recaptchaVerifier);
    }
  };

  const openResendCaptcha = () => {
    if (canResendCode > 0) {
      return;
    }
    setShowResendCode(true);
    setTimeout(() => {
      const appVerifier = new firebase.auth
        .RecaptchaVerifier('resendcode', {
          size: "normal", // 'invisible' is also an option
          hl: "fr-CA",
          callback: () => {
            setCaptchaValidated(true);
          }
        });
      appVerifier.render();
      setResendRecaptchaVerifier(appVerifier);
    }, 500)
  }

  const resendVerificationCode = async () => {
    firebase.auth().signInWithPhoneNumber(data.telephone, resendRecaptchaVerifier)
      .then((confirmationResult) => {
        setVerificationId(confirmationResult.verificationId);
        setShowResendCode(false);
        setCanResendCode(-1);
      })
  }

  useEffect(() => {
    if (canResendCode === 0) return;
    const interval = setTimeout(() => {
      setCanResendCode(canResendCode - 1);
    }, 1000);
    return () => clearTimeout(interval);
  }, [canResendCode]);
  return (
    <>
      <Fragment>
        <Modal centered show={show} onHide={() => setShow(false)}>
          <Modal.Body className={"p-5"}>
            <ul className="nav row" style={{ rowGap: "1rem" }}>
              <li className="col-12 ">
                <label
                  htmlFor="firstName"
                  className="font-poppins text-14 mb-1"
                >
                  <h3 className={"pb-3"}>
                    Nous vous avons envoyé un SMS
                  </h3>
                  <p>
                    Pour continuer, saisissez le code de vérification à 6 chiffres envoyé à <b>votre numéro de
                      téléphone {data.telephone ? `se terminant par ${data.telephone.substring(data.telephone.length - 4)}` : "."} </b>
                  </p>
                  {
                    canResendCode >= 0
                    &&
                    <p>Vous n'avez pas reçu le code ?
                      <span className={"text-primary"}
                        style={{ cursor: "pointer" }}

                        onClick={() => openResendCaptcha()}>
                        Renvoyer ({canResendCode}).
                      </span>
                    </p>
                  }
                  {showResendCode &&
                    <>
                      <div id={"resendcode"}></div>
                      <button
                        className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
                        onClick={resendVerificationCode}
                        disabled={!captchaValidated}
                      >
                        Envoyer
                      </button>
                    </>
                  }
                </label>

                <ReactCodeInput
                  className=" py-4  mb-2"
                  onChange={(e) => setVerificationCode(e)}
                />

                <button
                  disabled={
                    verificationCode.toString().length !== 6 ? true : false
                  }
                  className="btn text-12 btn-primary py-2 font-weight-600 font-poppins rounded-lg"
                  onClick={handleVerifyCode}
                >
                  {verifying ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Valider"
                  )}
                </button>
              </li>
            </ul>
          </Modal.Body>
        </Modal>
      </Fragment>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group mb-3">
          <label className="small fw-medium mb-1" htmlFor="username">
            Prénom et Nom*
          </label>
          <input
            className="form-control"
            name="username"
            type="text"
            {...register("username", { required: true })}
          />
          {errors.username?.type === "required" && (
            <span style={{ color: "red" }} className="text-danger">
              Ce champ est obligatoire
            </span>
          )}
        </div>
        <div className="form-group mb-3">
          <label className="small fw-medium mb-1" htmlFor="email">
            Email*
          </label>
          <input
            className="form-control"
            type="email"
            name="email"
            {...register("email", { required: true })}
          />
          {errors.email?.type === "required" && (
            <span style={{ color: "red" }} className="text-danger">
              Ce champ est obligatoire
            </span>
          )}
        </div>
        <div className="form-group mb-3">
          <label className="small fw-medium mb-1" htmlFor="phoneno">
            Numéro de téléphone*
          </label>
          <Controller
            name={`telephone`}
            control={control}
            defaultValue=""
            rules={{
              required: "Le numéro de téléphone est requis",
              validate: {
                validPhone: (value) =>
                  isValidPhoneNumber(value) || "Numéro de téléphone invalide",
              },
              // Add other validation rules for the phone number field
            }}
            render={({ field }) => (
              <PhoneInput
                defaultCountry={defaultCountry}
                // className="form-control  position-relative phone-input bg_input"
                // className="phone-input bg_input form-control py-4 font-poppins text-14"
                onChange={(e) => setValue(`telephone`, e)}
                placeholder="Entrez votre numéro de téléphone"
              />
            )}
          />
          {errors.telephone?.type === "required" && (
            <span style={{ color: "red" }} className="text-danger">
              Ce champ est obligatoire
            </span>
          )}
          {errors.telephone?.type === "validPhone" && (
            <span style={{ color: "red" }} className="text-danger">
              {errors?.telephone?.message}
            </span>
          )}
          {/* <PhoneInput
            country={"us"}
            containerClass="w-100"
            inputClass="w-100"
          /> */}
        </div>
        <div id="recaptcha"></div>
        {!watch("captcha") && (
          <span style={{ color: "red" }} className="text-danger">
            Ce champ est obligatoire
          </span>
        )}

        <button
          className="btn btn-primary w-100 py-2 fw-bold mt-4"
          type="submit"
        >
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            "Accéder à l'estimateur"
          )}
        </button>
      </form>
    </>
  );
};

export default LoginPage;
