import React from "react";

const StartEstimationPage = () => {
  const redirectToCa = () => {
    window.open(
        `https://ziaway.ca/homepage`,
        "_self"
    );
  }
  return (
    <main className="bg-gray-100 min-vh-100 d-flex flex-column">
      <section
          className="py-5 bg-cover-1"
          style={{ backgroundImage: `url(/assets/images/hero-image-1.jpg)` }}
      >
        <div className="container py-lg-4">
          <div className="text-center max-w-5 mx-auto">
            <div className="auth-logo text-center mb-1">
              <img
                  src="/assets/images/logo-2-v2white.png"
                  alt=".."
                  style={{ objectFit: "contain" }}
              />
            </div>
            <h1 className="mb-0 fs-3 fw-bold text-white">
              Comment s’assurer de vendre sa propriété au juste prix ?
            </h1>
          </div>
        </div>
      </section>
      <div className="p-3 p-sm-4">
        <div className="">
          <div className="card-body">
            <div className="row justify-content-center align-content-center flex-column">
              <div className="col-lg-6">
                <div className="ul-product-detail__image m-1">
                  <div style={{width: "100%", height: 360}}>
                    <div style={{width: "100%", height: "100%"}}>
                      <iframe
                          frameBorder={0}
                          allowFullScreen=""
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          title="Vendeur VMZ"
                          width="100%"
                          height="100%"
                          src="https://www.youtube.com/embed/WzKp4iOOOTg?autoplay=1&mute=0&controls=1&origin=https://lp.ziaway.ca&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=1&widgetid=1"
                          id="widget2"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ul-product-detail__brand-name my-4 flex-row justify-content-between">
                  <p>Vendez au prix juste avec notre outil gratuit d'estimation immobilière !</p>
                  <span className=" text-14 mt-4">
                    Découvrez la vraie valeur de votre bien avec notre estimateur immobilier instantané et gratuit. Utilisez notre outil dès aujourd'hui et prenez les bonnes décisions pour votre vente.
                  </span>
                  <button
                      className="btn btn-primary w-100 py-2 fw-bold mt-4"
                      onClick={redirectToCa}
                  >
                    Cliquez ici pour commencer votre estimation
                  </button>
                </div>

              </div>
            </div>
            <div className="row"/>
          </div>
        </div>
      </div>
    </main>
  );
};

export default StartEstimationPage;
