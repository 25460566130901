import React from "react";

const Footer = () => {
  return (
    <footer className="mt-5">
      <div className="container py-3 border-top">
        <div className="d-flex flex-wrap justify-content-center justify-content-md-between small">
          <p className="mb-0">© 2024 Ziaway, Inc. Tous droits réservés.</p>
          <ul className="list-unstyled d-flex">
            <li className="ms-3">
              <a
                href="https://ziaway.ca/dataPolicy/false"
                target="_blank"
                rel="noopener noreferrer"
                className="link-primary text-decoration-none"
              >
                Politique de confidentialité
              </a>
            </li>
            <li className="ms-3">
              <a
                href="https://ziaway.ca/termofservice"
                target="_blank"
                rel="noopener noreferrer"
                className="link-primary text-decoration-none"
              >
                Termes et conditions
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
