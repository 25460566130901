import React, { useContext, useEffect, useState } from "react";
import {
    Accordion,
    AccordionContext,
    Button,
    Card,
    Modal,
    useAccordionButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";

function ContextAwareToggle({ children, eventKey, callback }) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey)
    );

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <span
            onClick={decoratedOnClick}
            className="d-flex align-items-start cursor-pointer"
        >
      <span className="h3 mb-0 mr-1">{isCurrentEventKey ? "-" : "+"}</span>
      <span className="pt-1">{children}</span>
    </span>
    );
}

const Buttons = () => {
    const [check, setCheck] = useState("");
    return (
        <div className="d-flex" style={{ gap: "0.375rem", marginLeft: "0.375rem" }}>
            <Button
                size="sm"
                variant={`${check === "Refuserd" ? "danger" : "outline-danger"}`}
                onClick={() => setCheck("Refuserd")}
            >
                Refuser
            </Button>
            <Button
                size="sm"
                variant={`${check === "Accepter" ? "success" : "outline-success"}`}
                onClick={() => setCheck("Accepter")}
            >
                Accepter
            </Button>
        </div>
    );
};

const ButtonsPartners = () => {
    const [check, setCheck] = useState("");
    return (
        <div className="d-flex" style={{ gap: "0.375rem" }}>
            <Button
                size="sm"
                variant={`${check === "Bloquer" ? "danger" : "outline-danger"}`}
                onClick={() => setCheck("Bloquer")}
            >
                Bloquer
            </Button>
            <Button
                size="sm"
                variant={`${check === "Autoriser" ? "success" : "outline-success"}`}
                onClick={() => setCheck("Autoriser")}
            >
                Autoriser
            </Button>
        </div>
    );
};

const CookiesModal = () => {
    const [show, setShow] = useState("Your privacy is important to us");

    const [isCookiesAccepted, setIsCookiesAccepted] = useState(false);

    const handleClose = () => {
        localStorage.setItem("cookiesAccepted", true);
        setShow("");
    };

    const authorizeList = [
        {
            title: "Personnalisation de publicités par notre site et nos partenaires",
            text: "Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.",
            isButtons: true,
            link: "politique de confidentialité",
        },
        {
            title: "Mesure d'audience",
            text: "Ces cookies nous permettent de mesurer l'audience de notre site, de faire des statistiques et d'améliorer la qualité de nos services. Nous pouvons utiliser AT Internet, un outil de mesure d’audience bénéficiant d’une exemption au consentement afin de communiquer des statistiques anonymes sur la visibilité de nos annonces, à destination des professionnels de l'immobilier qui diffusent ces annonces sur nos plateformes. Pour en savoir plus, vous pouvez consulter notre Politique Générale de Protection des Données.",
            isButtons: true,
            link: "politique de confidentialité",
        },
        {
            title: "Fonctionnel",
            text: "Ces cookies sont nécessaires pour le fonctionnement du service.",
            boldText:
                "Cette finalité est requise par notre site pour fonctionner normalement et ne peut pas être désactivée.",
            subTitle: "REQUIS",
        },
    ];

    const partnersListOfA = [
        {
            title: "Algolia",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Algolia et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfC = [
        {
            title: "Criteo",
            subTitle: "Traitements de données basés sur le consentement:",
            text: "Vous pouvez en apprendre davantage sur Criteo et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Personnalisation de publicités par notre site et nos partenaires
                    </li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfD = [
        {
            title: "Datadome",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Datadome et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfF = [
        {
            title: "Facebook",
            subTitle: "Traitements de données basés sur le consentement:",
            text: "Vous pouvez en apprendre davantage sur Facebook et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Personnalisation de publicités par notre site et nos partenaires
                    </li>
                </>
            ),
            isButtons: true,
        },
        {
            title: "Font Awesome",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Font Awesome et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfG = [
        {
            title: "Google Advertising Products",
            subTitle: "Traitements de données basés sur le consentement:",
            text: "Vous pouvez en apprendre davantage sur Google Advertising Products et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Personnalisation de publicités par notre site et nos partenaires
                    </li>
                </>
            ),
            isButtons: true,
        },
        {
            title: "Google Analytics",
            subTitle: "Traitements de données basés sur le consentement:",
            text: "Vous pouvez en apprendre davantage sur Google Analytics et sur la façon dont cette entité traite les données dans sa ",
            points: (
                <>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Mesure d'audience
                    </li>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Personnalisation de publicités par notre site et nos partenaires
                    </li>
                </>
            ),
            isButtons: true,
        },
        {
            title: "Google Fonts",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Google Fonts et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
        {
            title: "Google reCAPTCHA",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Google reCAPTCHA et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
        {
            title: "Google Tag Manager",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Google Tag Manager et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfL = [
        {
            title: "LinkedIn",
            subTitle: "Traitements de données basés sur le consentement:",
            text: "Vous pouvez en apprendre davantage sur LinkedIn et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li title="Ces cookies ont pour but de vous proposer des publicités plus pertinentes car adaptées à vos centres d'intérêt. Ils peuvent être déposés par notre site et nos partenaires. Vous trouverez ci-dessous la liste de tous les partenaires.">
                        Personnalisation de publicités par notre site et nos partenaires
                    </li>
                </>
            ),
            isButtons: true,
        },
    ];

    const partnersListOfY = [
        {
            title: "Youtube",
            subTitle: "Traitements de données requis:",
            text: "Vous pouvez en apprendre davantage sur Youtube et sur la façon dont cette entité traite les données dans sa",
            points: (
                <>
                    <li>Fonctionnel</li>
                </>
            ),
            isButtons: true,
        },
    ];

    useEffect(() => {
        const ck = localStorage.getItem("cookiesAccepted");
        setIsCookiesAccepted(ck);
    }, []);
    return (
        <>
            {!isCookiesAccepted && (
                <Modal
                    show={show === "Your privacy is important to us"}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    size={"lg"}
                    contentClassName={"cookiesModal"}
                >
                    <Modal.Header>
                        <Modal.Title className="text-primary">{"Votre vie privée nous importe"}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{"Nous utilisons des cookies et des technologies similaires pour mieux comprendre l’utilisation de notre site Web, optimiser ses fonctionnalités, améliorer l’expérience utilisateur, maintenir notre site Web sécurisé et fonctionnel et offrir du contenu adapté à vos intérêts. En cliquant sur le bouton « Tout accepter » ci-dessous, vous autorisez l’utilisation des cookies et technologies similaires telle que présentée dans notre Ziaway-Politique de confidentialité."}</p>

                        <p>{" Liste de tous les cookies que nous utilisons :"}</p>
                        <a href="https://ziaway.ca/dataPolicy" target="_blank" className={"text-primary"} style={{ fontSize: "0.875em"}}> {"Politique de confidentialité"}</a>
                    </Modal.Body>
                    <Modal.Footer className={"d-flex flex-wrap flex-sm-nowrap align-content-between justify-content-between"}>
                        <p style={{width: 250, fontSize: "0.875em"}}>{"En cliquant sur 'Accepter', vous acceptez nos termes et conditions."}</p>
                        <div style={{width: 335}}>
                            <Button
                                variant="outline-primary"
                                className="mx-2"
                                onClick={() => setShow("Paramètres des cookies")}
                            >
                                {"Personnaliser mes choix"}
                            </Button>
                            <Button variant="primary" onClick={handleClose}>
                                {"Accepter"}
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}

            <Modal
                show={show === "Paramètres des cookies"}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                scrollable
                size={"lg"}
                contentClassName={"cookiesModal"}
            >
                <Modal.Header>
                    <Modal.Title className="text-primary">{"Paramètres des cookies"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{"Ziaway technologies et ses partenaires utilisent des cookies et technologies similaires pour différentes finalités. La présente page de paramétrage vous permet de personnaliser vos choix à tout moment."}</p>

                    <p>{"Elle s'applique aux différentes parties du Site (https://ziaway.ca/homepage)ainsi qu'aux domaines ou sous-domaine (https://ziaway.ca/blog), (https://pro.ziaway.ca) et (https://proziaway.com). Lorsque vous consultez le Site, les domaines ou sous domaines (https ://ziaway.ca/homepage), (https://ziaway.ca/blog), (https://pro.ziaway.ca) et (https://proziaway.com), des données sont susceptibles d'être écrites et/ou collectées par l'intermédiaire de votre logiciel de navigation, dans de courts fichiers textuels (cookies) ou via d'autres technologies similaires."}</p>

                    <p>{"Sous réserve de vos choix, ces données peuvent notamment inclure des informations concernant votre appareil, votre navigateur, votre connexion, vos interactions avec notre Site et les données techniques associées, le lien suivi pour arriver sur notre Site, ainsi que les données échangées avec les partenaires auxquels vous consentez."}</p>

                    <p>
                        {"Pour plus d'informations, nous vous invitions à consulter notre "}
                        <a className={"text-primary"} style={{ fontSize: "0.875em"}} href="https://ziaway.ca/dataPolicy" target="_blank">{"politique de confidentialité."}</a>
                    </p>

                    <p className="font-weight-bold">{"VOUS AUTORISEZ"}</p>
                    <Accordion>
                        {authorizeList.map((item, index) => (
                            <Card key={`authorize-${index}`}>
                                <Card.Header>
                                    <div className="d-flex align-items-start justify-content-between">
                                        <div className="mr-auto pr-2">
                                            <ContextAwareToggle eventKey={item.title}>
                                                {item.title}
                                            </ContextAwareToggle>
                                        </div>
                                        {item.isButtons && <Buttons />}
                                        {item.subTitle && (
                                            <div className="mb-0 font-weight-bold">
                                                {item.subTitle}
                                            </div>
                                        )}
                                    </div>
                                </Card.Header>
                                <Accordion.Collapse eventKey={item.title}>
                                    <Card.Body>
                                        <p className="mb-0">{item.text}</p>
                                        {item?.link && (
                                            <a className={"text-primary"} style={{ fontSize: "0.875em"}} href="https://ziaway.ca/dataPolicy" target="_blank">{item?.link}</a>
                                        )}
                                        {item.boldText && (
                                            <p className="mt-3 mb-0 font-weight-bold">
                                                {item.boldText}
                                            </p>
                                        )}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        ))}
                    </Accordion>
                    <div className="text-right mt-3">
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setShow("partners")}
                        >
                            {"Voir nos partenaires"}
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-end">
                        <Button
                            variant="outline-primary"
                            className="mx-2"
                            onClick={handleClose}
                        >
                            {"Refuser tout"}
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            {"Accepter tout"}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
                show={show === "partners"}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                scrollable
                size="lg"
                contentClassName={"cookiesModal"}
            >
                <Modal.Header>
                    <Modal.Title
                        className="text-primary cursor-pointer"
                        onClick={() => setShow("Paramètres des cookies")}
                    >
                        <div className="d-flex align-items-baseline">
                            <i className="icon i-Arrow-Back-3 mr-2"></i>
                            <span>{"Sélectionner les partenaires pour Ziaway"}</span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{"Vous pouvez définir vos préférences de consentement pour chaque partenaire listé ci-dessous individuellement. Cliquez sur le nom d'un partenaire pour obtenir plus d'informations sur ce qu'il fait, les données qu'il récolte et comment il les utilise."}</p>

                    <div>
                        <div className="bg-gray-200 p-2 rounded d-flex align-items-center justify-content-between">
                            <b className="mr-auto"> {"Tous les partenaires"}</b>
                            <ButtonsPartners />
                        </div>
                        <div className="overflow-auto py-3" style={{ height: "18.75rem" }}>
                            <ul
                                className="nav flex-column flex-nowrap"
                                style={{ gap: "1rem" }}
                            >
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        A
                                    </h5>
                                    <Accordion>
                                        {partnersListOfA.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <a
                                                                href="https://ziaway.ca/dataPolicy"
                                                                target={"_blank"}
                                                                className={"text-primary"} style={{ fontSize: "0.875em"}}
                                                            >
                                                                {" politique de confidentialité"}
                                                            </a>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        C
                                    </h5>
                                    <Accordion>
                                        {partnersListOfC.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <a
                                                                className={"text-primary"} style={{ fontSize: "0.875em"}}
                                                                href="https://ziaway.ca/dataPolicy" target="_blank"
                                                            >
                                                                {" politique de confidentialité"}
                                                            </a>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        D
                                    </h5>
                                    <Accordion>
                                        {partnersListOfD.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <a
                                                                className={"text-primary"} style={{ fontSize: "0.875em"}}
                                                                href="https://ziaway.ca/dataPolicy" target="_blank"
                                                            >
                                                                {" politique de confidentialité"}
                                                            </a>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        F
                                    </h5>
                                    <Accordion>
                                        {partnersListOfF.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <a
                                                                className={"text-primary"} style={{ fontSize: "0.875em"}}
                                                                href="https://ziaway.ca/dataPolicy" target="_blank"
                                                            >
                                                                {" politique de confidentialité"}
                                                            </a>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        G
                                    </h5>
                                    <Accordion>
                                        {partnersListOfG.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <a
                                                                className={"text-primary"} style={{ fontSize: "0.875em"}}
                                                                href="https://ziaway.ca/dataPolicy" target="_blank"
                                                            >
                                                                {" politique de confidentialité"}
                                                            </a>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        L
                                    </h5>
                                    <Accordion>
                                        {partnersListOfL.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <a
                                                                className={"text-primary"} style={{ fontSize: "0.875em"}}
                                                                href="https://ziaway.ca/dataPolicy" target="_blank"
                                                            >
                                                                {" politique de confidentialité"}
                                                            </a>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                                <li>
                                    <h5 className="mb-2 font-weight-bold text-uppercase px-3">
                                        Y
                                    </h5>
                                    <Accordion>
                                        {partnersListOfY.map((item, index) => (
                                            <Card key={`authorize-${index}`}>
                                                <Card.Header>
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div className="mr-auto pr-2">
                                                            <ContextAwareToggle eventKey={item.title}>
                                                                {item.title}
                                                            </ContextAwareToggle>
                                                        </div>
                                                        {item.isButtons && <Buttons />}
                                                    </div>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={item.title}>
                                                    <Card.Body>
                                                        {item.subTitle && (
                                                            <div className="mb-0 font-weight-bold">
                                                                {item.subTitle}
                                                            </div>
                                                        )}

                                                        <ul className="pl-3">{item.points}</ul>

                                                        <div>
                                                            {item.text && (
                                                                <p className="mb-0 d-inline-block mr-1">
                                                                    {item.text}
                                                                </p>
                                                            )}
                                                            <a
                                                                className={"text-primary"} style={{ fontSize: "0.875em"}}
                                                                href="https://ziaway.ca/dataPolicy" target="_blank"
                                                            >
                                                                {" politique de confidentialité"}
                                                            </a>
                                                        </div>
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))}
                                    </Accordion>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <Accordion>
                                <Card className="bg-gray-200">
                                    {/* <Card.Header>
                    <div className="d-flex align-items-start justify-content-between">
                      <div className="mr-auto pr-2">
                        <ContextAwareToggle eventKey="Voir les infos de l'utilisateur">
                          {"cookies.21"
                        </ContextAwareToggle>
                      </div>
                    </div>
                  </Card.Header> */}
                                    <Accordion.Collapse eventKey="Voir les infos de l'utilisateur">
                                        <Card.Body>
                                            <div className="d-flex">
                                                <b className="mr-1" style={{ minWidth: "6.25rem" }}>
                                                    {"ID utilisateur:"}
                                                </b>
                                                <span style={{ wordBreak: "break-all" }}>
                          184b9fcc-b526-66de-ad15-0fa5b917242f
                        </span>
                                            </div>
                                            <div className="mt-3 d-flex">
                                                <b className="mr-1" style={{ minWidth: "6.25rem" }}>
                                                    {"Token Didomi:"}
                                                </b>
                                                <span style={{ wordBreak: "break-all" }}>
                          eyJ1c2VyX2lkIjoiMTg0YjlmY2MtYjUyNi02NmRlLWFkMTUtMGZhNWI5MTcyNDJmIiwiY3JlYXRlZCI6IjIwMjItMTEtMjdUMTY6NDc6NDIuOTU5WiIsInVwZGF0ZWQiOiIyMDIyLTExLTI3VDE2OjQ3OjQyLjk1OVoiLCJ2ZXJzaW9uIjpudWxsfQ==
                        </span>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </div>
                    </div>

                    <div className="text-right mt-3">
                        <Button
                            size="sm"
                            variant="outline-primary"
                            onClick={() => setShow("partners")}
                        >
                            {"Voir nos partenaires"}
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-end">
                        <Button
                            variant="outline-primary"
                            className="mx-2"
                            onClick={handleClose}
                        >
                            {"Refuser tout"}
                        </Button>
                        <Button variant="primary" onClick={handleClose}>
                            {"Accepter tout"}
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CookiesModal;